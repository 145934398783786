import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-header-img-wrap">
          <img src="./Me.jpg" />
        </div>
        <div className="App-header-name">
          Noah Marsh
          <div className="App-header-title">
            Front-end Engineer
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
